import React from "react"
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import TrialForm from '../components/TrialForm'
import TRIAL from '../images/trial/trial.svg'
import '../styles/TwoCols'

export default function TrialPage() {
  const root = useClasses()
  return (
    <Layout short>
      <SEO title='Trial' description='Free no strings-attached trial of OneOffice'/>
      <main className="main" ref={root}>

        <span className="heading-separator"/>
        <h1 className="title">OneOffice Trial</h1>
        <h2 className="subtitle">You are one step closer to the next big thing!</h2>

        <div className="twocols-tight my-10">
          <div className="twocols-col-image">
            <img src={TRIAL}/>
          </div>
          <div className="twocols-col-text-left">
            <TrialForm className='md:max-w-md'/>
          </div>
        </div>

        <p className='text-base font-light mb-2 text-gray-600'>
          We will respond back in one business day at the most. 
          Please check your spam folder. 
          Your info will be used to create your trial account and keep you up to date.
        </p>
        <p className='text-base font-semibold mb-5 text-primary-dark'>
          Please note that we may contact you if we have specific queries to create your ideal setup.
        </p>
      </main>
    </Layout>
  )
}

