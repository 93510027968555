import GeoLocation from '../components/GeoLocation'
import { setRegistered } from '../lib/Marketing'

const FORM_ID = '4'
const FORM_NAME = 'trialoo'
const MAUTIC_URL = 'https://mautic.oneoffice.ca/form/submit?formId=' + FORM_ID

const PostTrial = function({first, last, email, phone, company, role, employees}) {
  return new Promise(function(resolve) {
    const formData = new FormData()
    formData.append('mauticform[first_name]', first)
    formData.append('mauticform[last_name]', last)
    formData.append('mauticform[email]', email)
    formData.append('mauticform[phone]', phone)
    formData.append('mauticform[company]', company)
    formData.append('mauticform[role]', role)
    formData.append('mauticform[employees]', employees)
    formData.append('mauticform[country]', GeoLocation.country)
    formData.append('mauticform[city]', GeoLocation.city)
    formData.append('mauticform[state]', GeoLocation.region)
    formData.append('mauticform[partner]', GeoLocation.partner)
    formData.append('mauticform[submit]', '')
    formData.append('mauticform[formId]', FORM_ID)
    formData.append('mauticform[messenger]', '1')
    formData.append('mauticform[formName]', FORM_NAME)
  
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        const result = xhr.status == 200
        if (result) setRegistered()
        resolve(result)
      }
    }
    xhr.open('POST', MAUTIC_URL)
    // https://gist.github.com/escopecz/136f62b66d526bc32006f0d2358a8e15
    // otherwise we will get CORS error
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.send(formData)
  })
}

export default PostTrial
// if (typeof window != 'undefined') window.postTrial = PostTrial
